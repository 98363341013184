import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { FileAttachmentDialogComponent } from 'src/app/components';
import { FOUND_PAGE, ResourceType } from 'src/app/enums';
import { AuthService, FileService, LocationService, ProgressIndicatorService } from 'src/app/services';
import { User } from 'src/app/types';

@Component({
  selector: 'app-department-profile',
  templateUrl: './department-profile.component.html',
  styleUrls: ['./department-profile.component.scss'],
})
export class DepartmentProfileComponent implements OnInit {
  loading = true;
  currentUser: User;
  id: number;
  department;
  editingDetails = false;
  detailsFormGroup: FormGroup = this.fb.group({});
  fields = [
    'id',
    'name',
    'department_affiliations{user{title,is_enabled},is_ar}',
    'files',
    'suite_occupancies{suite{floor},department,building}',
    'is_enabled',
  ];
  users = [];
  ARs = [];
  buildings = [];
  suites = [];
  suiteOccupancies = [];
  canEdit = false;

  constructor(
    public authService: AuthService,
    private activeRoute: ActivatedRoute,
    private locationService: LocationService,
    private _fileService: FileService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private fb: FormBuilder,
    private progressIndicatorService: ProgressIndicatorService
  ) {}

  async ngOnInit(): Promise<void> {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Loading...');
    this.canEdit =
      this.authService.isAppAdmin ||
      this.authService.isBuildingsDepartmentsSuitesManager ||
      this.authService.isDirectoryManager;
    this.buildings = await this.locationService.getBuildings(['id', 'name', 'floors']).toPromise();
    this.suites = await this.locationService.getSuites(['id', 'name', 'floor_id']).toPromise();
    this.currentUser = this.authService.getLoggedInUser();
    this.id = +this.activeRoute.snapshot.paramMap.get('id');
    const department = (await this.locationService.getDepartmentByID(this.id, this.fields).toPromise()) as any;
    if (department) {
      department.department_affiliations.forEach((da) => {
        if (da.is_ar === 1) {
          this.ARs.push(da.user);
        } else {
          this.users.push(da.user);
        }
      });
      this.mapDepartment(department);
    } else {
      this._router.navigate(['/404'], {
        queryParams: {
          status: FOUND_PAGE.NOT_FOUND,
        },
      });
    }
    this.progressIndicatorService.close();
    this.loading = false;
  }

  async mapDepartment(department) {
    this.suiteOccupancies = [...department.suite_occupancies];
    this.suiteOccupancies.forEach((so) => {
      so.buildingForm = new FormControl(so.building_id, [Validators.required]);
      so.floorForm = new FormControl(so.floor_id, [Validators.required]);
      so.suiteForm = new FormControl(so.suite_id, [Validators.required]);
      this.getFloors(so);
    });
    this.department = department;
    this.detailsFormGroup = this.fb.group({
      name: [this.department.name, [Validators.required]],
      is_enabled: [this.department.is_enabled === 1 ? true : false],
    });
  }

  public addFile() {
    this._dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Department,
          parentResourceId: this.department.id,
          workOrderFile: true,
          allowComment: false,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {
        if (Array.isArray(result) && result[0].id) {
          this.department.files = [...result, ...(this.department.files || [])];
          this._snackBar.open('File has been added!');
        }
      });
  }

  toggleDetails() {
    this.editingDetails = true;
  }

  async updateProfile() {
    if (!this.detailsFormGroup.valid) {
      return;
    }
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Saving...');
    const departmentToUpdate = this.detailsFormGroup.value;
    departmentToUpdate.is_enabled = departmentToUpdate.is_enabled === true ? 1 : 0;

    const requests = [];
    this.suiteOccupancies.forEach((so) => {
      if (!so.suiteForm.value || !this.department.id) {
        return;
      }
      const suiteOccupancyToAddOrUpdate = { department_id: this.department.id, suite_id: so.suiteForm.value };

      if (!so.id) {
        const request = this.locationService.addSuiteOccupancy(suiteOccupancyToAddOrUpdate);
        requests.push(request);
      } else if (so.suiteForm.value) {
        const currentSuiteOccupancy = this.department.suite_occupancies.find((cso) => cso.id === so.id);
        if (currentSuiteOccupancy && currentSuiteOccupancy.suite_id !== so.suiteForm.value) {
          const request = this.locationService.updateSuiteOccupancy(so.id, suiteOccupancyToAddOrUpdate);
          requests.push(request);
        }
      }
    });

    this.department.suite_occupancies.filter((cso) => {
      if (!this.suiteOccupancies.find((so) => so.id === cso.id)) {
        const request = this.locationService.deleteSuiteOccupancy(cso.id);
        requests.push(request);
      }
    });

    await forkJoin(requests)
      .toPromise()
      .then(() => {});
    const updatedDepartment = await this.locationService
      .updateDepartment(this.department.id, departmentToUpdate, this.fields)
      .toPromise();
    this.mapDepartment(updatedDepartment);

    this.editingDetails = false;
    this.progressIndicatorService.close();
  }

  cancel() {
    this.detailsFormGroup.patchValue({
      name: this.department.name,
      address: this.department.address,
      phone: this.department.phone,
      website: this.department.website,
      is_enabled: this.department.is_enabled === 1 ? true : false,
    });
    this.suiteOccupancies = [...this.department.suite_occupancies];
    this.editingDetails = false;
  }

  addSuite() {
    this.suiteOccupancies.push({
      buildingForm: new FormControl(null, [Validators.required]),
      floorForm: new FormControl(null, [Validators.required]),
      suiteForm: new FormControl(null, [Validators.required]),
    });
  }

  refreshFloors(suiteOccupancy) {
    this.getFloors(suiteOccupancy);
    suiteOccupancy.suiteForm.value = null;
    suiteOccupancy.floorForm.value = null;
    this.getSuites(suiteOccupancy);
  }

  getFloors(suiteOccupancy) {
    const building = this.buildings.find((b) => b.id === suiteOccupancy.buildingForm.value);
    suiteOccupancy.floors = building?.floors || [];
    this.getSuites(suiteOccupancy);
  }

  getSuites(suiteOccupancy) {
    suiteOccupancy.filteredSuites = this.suites?.filter((s) => s.floor_id === suiteOccupancy.floorForm.value);
  }

  refreshSuites(suiteOccupancy) {
    suiteOccupancy.suiteForm.value = null;
    this.getSuites(suiteOccupancy);
  }

  removeSuiteOccupancy(suiteOccupancy) {
    const index = this.suiteOccupancies.indexOf(suiteOccupancy);
    this.suiteOccupancies.splice(index, 1);
  }
}
