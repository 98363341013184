import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Fuse from 'fuse.js';
import { CompanyTypeEnum, UserType, Workspace } from 'src/app/enums';
import {
  AuthService,
  CompanyService,
  LocationService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
  WorkOrderService,
} from 'src/app/services';
import { US_States } from 'src/app/utils';
import { APIFilter, Company } from '../../types';

@Component({
  selector: 'app-company-dialog',
  templateUrl: './company-dialog.component.html',
  styleUrls: ['./company-dialog.component.scss'],
})
export class CompanyDialogComponent implements OnInit {
  // @ViewChild('signator', { static: true }) private signator: SearchUserInputComponent;
  // @ViewChild('primary_contact', { static: true }) private primary_contact: SearchUserInputComponent;
  USstates = US_States;
  constructor(
    public dialogRef: MatDialogRef<CompanyDialogComponent>,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private projectService: ProjectService,
    private locationService: LocationService,
    private progressIndicatorService: ProgressIndicatorService,
    private authService: AuthService,
    private workOrderService: WorkOrderService,
    private router: Router,
    private modalService: ModalService
  ) {}

  showSuggestions = false;

  companyFormGroup: FormGroup = this.fb.group({
    type_id: [this.data && this.data.company ? this.data.company.type_id : '', [Validators.required]],
    name: [this.data && this.data.company ? this.data.company.name : '', [Validators.required]],
    contact_name: [this.data && this.data.company ? this.data.company.contact_name : '', [Validators.required]],
    contact_email: [
      this.data && this.data.company ? this.data.company.contact_email : '',
      [Validators.required, Validators.email],
    ],
    trade_ids: [this.data?.company?.trade_ids || [], this.data?.showTrades ? [Validators.required] : null],
    phone: [this.data?.company?.phone || '', []],
    website: [this.data?.company?.website || '', []],
    address: this.fb.group({
      address: [this.data.company?.address?.address],
      city: [this.data.company?.address?.city],
      state: [this.data.company?.address?.state],
      zipcode: [this.data.company?.address?.zipcode],
    }),
    verified: [this.data && this.data.company ? this.data.company.verified : ''],
    enabled: [this.data && this.data.company ? this.data.company.is_enabled : ''],
    verification_status: [this.data?.company?.verification_status || ''],
  });
  isAddressActive = new FormControl(false);
  userTypes = [UserType.Staff, UserType.Vendor, UserType.Tenant];
  companyTypes = [];
  trades = [];
  companyFields = ['id', 'name', 'type_id', 'trade_ids', 'address', 'phone', 'website'];
  companies: Company[] = [];
  fuzzyMatchingCompanies = [];
  displayExistingCompanyMax = 3;

  get canEditCompany() {
    return this.authService.isAppAdmin || this.authService.isAC || this.authService.isDirectoryManager;
  }

  get showSupplierVerification() {
    return (this.authService.isAppAdmin || this.authService.isACOnAnyModule) && this.data?.company?.id;
  }

  get type_id() {
    return this.companyFormGroup.get('type_id');
  }
  get name() {
    return this.companyFormGroup.get('name');
  }
  get contact_name() {
    return this.companyFormGroup.get('contact_name');
  }
  get contact_email() {
    return this.companyFormGroup.get('contact_email');
  }
  get trade_ids() {
    return this.companyFormGroup.get('trade_ids');
  }
  get address() {
    return this.companyFormGroup.get('address') as FormGroup;
  }

  CompanyType = CompanyTypeEnum;

  async ngOnInit() {
    if (this.data?.showTrades) {
      this.trades = await this.projectService
        .getTrades([{ type: 'field', field: 'allow_companies', value: '1' }])
        .toPromise();
    }

    this.companyTypes = await this.companyService.getCompanyTypes(['id', 'name']).toPromise();

    this.companies =
      this.data?.showExistingCompanies && !this.data?.companies?.length
        ? await this.companyService.getCompanies(['id', 'name']).toPromise()
        : this.data?.companies || [];

    this.searchExistingCompanies();

    // if (this.data?.company?.signator) {
    //   this.signator.searchEntry.setValue(this.data.company.signator);
    // }
    // if (this.data?.company?.primary_contact) {
    //   this.primary_contact.searchEntry.setValue(this.data.company.primary_contact);
    // }
    this.address.setValidators(this.addressValidator());
    if (this.data?.company?.address?.id) {
      this.isAddressActive.setValue(true);
    }
    if (this.data?.company?.id) {
      this.type_id.disable();
      this.contact_email.setValidators([Validators.email]);
      this.contact_name.clearValidators();
    }
  }

  searchExistingCompanies() {
    this.fuzzyMatchingCompanies = [];
    const searchTerm = this.name.value;
    if (searchTerm && this.companies?.length) {
      const options = {
        includeScore: true,
        keys: ['name'],
        minMatchCharLength: 3,
        threshold: 0.3,
      };

      const fuse = new Fuse(this.companies, options);
      const result = fuse.search(searchTerm);

      this.fuzzyMatchingCompanies =
        searchTerm.length > 2 ? (this.fuzzyMatchingCompanies = result.map((searchResult) => searchResult.item)) : [];
    }
  }

  selectExistingCompany(company) {
    // this.name.setValue(company.name);
    // if (this.companyFormGroup.valid) {
    this.dialogRef.close(company);
    // }
  }

  showMoreCompanies() {
    this.displayExistingCompanyMax += 4;
    setTimeout(() => {
      const existingCompaniesElement = document.getElementById('companies');
      existingCompaniesElement.scrollTo({ top: existingCompaniesElement.scrollHeight, behavior: 'smooth' });
    });
  }

  public addressValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const values = [
        group.controls['address'],
        group.controls['city'],
        group.controls['state'],
        group.controls['zipcode'],
      ];
      if (values.every((v) => v.value) || values.every((v) => !v.value)) {
        values.forEach((v) => v.setErrors(null));
      } else {
        values.forEach((v) => (v.value ? v.setErrors(null) : v.setErrors({ notEquivalent: true })));
      }
      return;
    };
  }

  async submit() {
    this.name.setValue(this.name.value?.trim());
    this.contact_name.setValue(this.contact_name.value?.trim());
    this.contact_email.setValue(this.contact_email.value?.trim());
    if (this.companyFormGroup.valid) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Saving...');
      const companyToSubmit: any = {
        name: this.name.value,
        contact_name: this.contact_name.value,
        contact_email: this.contact_email.value,
        trade_ids: JSON.stringify(this.trade_ids.value),
        phone: this.companyFormGroup.get('phone').value,
        website: this.companyFormGroup.get('website').value,
        // signator_id: this.signator.searchEntry.value ? this.signator.searchEntry.value.id : null,
        // primary_contact_id: this.primary_contact.searchEntry.value ? this.primary_contact.searchEntry.value.id : null,
        is_enabled: this.companyFormGroup.get('enabled').value ? 1 : 0,
      };

      const companyAddress = this.companyFormGroup.get('address').value;
      if (this.isAddressActive.value === false && this.data?.company?.address?.id) {
        companyToSubmit.address_id = null;
      } else if (Object.values(companyAddress).every((v) => v)) {
        const address = await this.locationService.addAddress(companyAddress);
        companyToSubmit.address_id = address.id;
      }
      let companyToReturn;
      if (this.data && this.data.company && this.data.company.id) {
        (companyToSubmit.verification_status = this.companyFormGroup.get('verification_status').value),
          (companyToReturn = await this.companyService
            .updateCompany(this.data.company.id, companyToSubmit, this.companyFields)
            .toPromise());
      } else {
        companyToSubmit.type_id = +this.type_id.value;
        companyToSubmit.is_enabled = 1;
        companyToReturn = await this.companyService.createCompany(companyToSubmit, this.companyFields).toPromise();
        // Suppliers
        if (companyToReturn?.type_id === CompanyTypeEnum.Supplier) {
          const accountsCoordinatorFilter: APIFilter[] = [
            { type: 'field', field: 'role_id', value: 35 }, // Accounts Coordinator
            { type: 'operator', value: 'AND' },
            { type: 'field', field: 'resource_id', value: 19 }, // UHAT Admin Workspace
          ];
          const accountsCoordinator = await this.authService
            .getAccess(['user_id'], accountsCoordinatorFilter)
            .toPromise();
          if (accountsCoordinator?.[0]?.user_id) {
            const workOrder = {
              module_id: Workspace.Compliance,
              title: `New Supplier Company - ${companyToReturn.name}`,
              building_id: 6,
              floor_id: 32,
              summary: `Verify Supplier Company:<br /><br />
              Name: ${companyToReturn.name}<br />
              ${companyToReturn.contact_name ? `Contact Name: ${companyToReturn.contact_name}<br />` : ''}
              ${companyToReturn.contact_email ? `Contact Email: ${companyToReturn.contact_email}<br />` : ''}
              ${companyToReturn.phone ? `Phone: ${companyToReturn.phone}<br />` : ''}
              ${companyToReturn.website ? `Website: ${companyToReturn.website}<br />` : ''}
              ${
                companyToReturn.address
                  ? `Address: ${companyToReturn.address.address}, ${companyToReturn.address.city}, ${companyToReturn.address.state} ${companyToReturn.address.zipcode}<br />`
                  : ''
              }`,
              topic_id: 515,
              requester_id: -100,
              assigned_user_id: accountsCoordinator[0].user_id,
              follower_ids: `[${this.authService.currentUser.id}]`,
            };
            const createdWorkOrder = await this.workOrderService.createWorkOrder(workOrder).toPromise();
            if (createdWorkOrder?.id) {
              this.modalService
                .openConfirmationDialog({
                  titleBarText: 'Company Created',
                  headerText: 'Supplier Companies must be reviewed ',
                  descriptionText: `A Work Order has been submitted and assigned to the Inventory Control Officer to review and verify the company. You will be notified when the company is verified and available to awarding.`,
                  cancelButtonText: 'Close',
                  confirmationButtonText: 'View Work Order',
                })
                .subscribe((answer: boolean): void => {
                  if (answer) {
                    void this.router.navigateByUrl(`/work-orders/${createdWorkOrder.id}`);
                  }
                });
            }
          }
        }
      }
      this.dialogRef.close(companyToReturn);
      this.progressIndicatorService.close();
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
