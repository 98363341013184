import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { intersection, uniq } from 'lodash';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApplicationRole, ResourceType, TaskAccessoryType, UserType, Workspace } from '../enums';
import { Access, APIFilter, Auth, Module, ServiceResponse, User } from '../types';
import { ArfCustodyChain } from '../types/arf-custody-chain';
import { ApiFilterService } from './api-filter.service';
import { HandleErrorService } from './handle-error.service';
import { ModuleService } from './module.service';
import { ProgressIndicatorService } from './progress-indicator.service';
import { SocketService } from './socket.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private handleErrorService: HandleErrorService,
    private socketService: SocketService,
    private apiFilterService: ApiFilterService,
    private progressIndicatorService: ProgressIndicatorService,
    public moduleService: ModuleService,
    private userService: UserService
  ) {
    // need to change this to reload anytime the workspace is set or changes
    this.moduleService.selectWorkspaceEvent.subscribe((workspace: Module) => {
      this.setAuthRoles();
    });
    this.setAuthRoles();
    this.setUserIsLoggedIn();
  }

  private host: string = environment.serviceHost;

  loginUrl = `${this.host}/api/v1/auth/login`;
  logoutUrl = `${this.host}/api/v1/auth/logout`;
  refreshUrl = `${this.host}/api/v1/auth/refresh`;
  impersonateUrl = `${this.host}/api/v1/auth/impersonate`;
  accessUrl = `${this.host}/api/v1/access`;
  companyAccessUrl = `${this.host}/api/v1/access-companies`;
  troubleshootUrl = `${this.host}/api/v1/auth/troubleshootLogin`;

  public appAdminRoles: number[] = [ApplicationRole.AppAdmin];

  public moduleAdminRoles: number[] = [
    ApplicationRole.ModuleAdmin,
    ApplicationRole.WorkspaceManager,
    ApplicationRole.ArchitectAdmin,
    ApplicationRole.AccountCoordinator,
    ApplicationRole.ChiefFacilitiesManagementOfficer,
    ApplicationRole.CEO,
  ];

  public projectAdminRoles: number[] = [
    ApplicationRole.ProjectManager,
    ApplicationRole.WorkspaceManager,
    ApplicationRole.AccountCoordinator,
    ApplicationRole.ChiefFacilitiesManagementOfficer,
    ApplicationRole.ProjectArchitect,
  ];

  // TODO change this to query roles where user_type_id = 2
  public tenantRoles: number[] = [
    ApplicationRole.ProjectRequester,
    ApplicationRole.ProjectContact,
    ApplicationRole.CustomerProjectRepresentative,
    ApplicationRole.AuthorizedRequester,
    ApplicationRole.OUPDUser,
  ];
  // TODO change this to query vendor roles excluding engineer
  public vendorRoles: number[] = [
    ApplicationRole.VendorAdmin,
    ApplicationRole.VendorUser,
    ApplicationRole.ProjectVendor,
  ];
  // TODO change this to query engineer roles + vendor roles
  public engineerVendorRoles: number[] = [ApplicationRole.ProjectEngineer, ApplicationRole.ModuleEngineer];

  // TODO change this to query roles where user_type_id = 1
  public staffRoles: number[] = [ApplicationRole.Staff, ApplicationRole.InventoryControlOfficer];

  public moduleProjectManager: number[] = [ApplicationRole.ModuleProjectManager];
  public workspaceTopicEditor: number[] = [ApplicationRole.WorkspaceTopicEditor];

  private editableReviewTypes = [
    TaskAccessoryType.Invoice,
    TaskAccessoryType.Other,
    TaskAccessoryType.Budget,
    TaskAccessoryType.ChangeOrder,
    TaskAccessoryType.Submittals,
    TaskAccessoryType.BidContract,
  ];
  private openReviewTypes = [TaskAccessoryType.KeyControls];

  private signatureReviewTypes = [TaskAccessoryType.Arf];

  private vendorReviewTypes = [TaskAccessoryType.BidContract, TaskAccessoryType.ProjectTimeline];

  // these roles relate to a (temporary?) way in which we can skip tenant approval if we already have it
  // if the tenant has previously approved the process outside of the site, we can use these roles to force an approval
  public impersonateTenantRoles: number[] = [
    ApplicationRole.AppAdmin,
    ApplicationRole.WorkspaceManager,
    ApplicationRole.ChiefFacilitiesManagementOfficer,
    ApplicationRole.ModuleAdmin,
  ];

  // TODO update these as we get more
  // public CURRENT_MODULE = 1; moved this into a module service
  public CURRENT_APP = 1;

  public currentUser: User;
  private isUserLoggedIn = false;
  private isAppAdminUser = false;
  private isTenantUser = false;
  private isCFMOUser = false;
  private isACUser = false;
  private isARUser = false;
  private isFinanceManagerUser = false;
  private isOUPDUser = false;
  private isICOUser = false;
  private isProfileEditorUser = false;
  private isARManagerUser = false;
  private isStaffUserOnAnyModule = false;
  private isVendorUserOnAnyProject = false;
  private isEngineerUserOnAnyProject = false;
  private canImpersonateTenantUser = false;
  private isBuildingsDepartmentsSuitesUser;
  public ceo: User;
  public isImpersonating = false;
  private isCreedEditor = false;
  private isExtSecurityUser = false;
  private isWorkspaceManagerUser = false;
  private isWorkspaceManagerUserOnAnyModule = false;
  private isDataAnalystUser = false;
  public isACOnAnyModule = false;
  private isDirectoryManagerUser = false;

  private async setAuthRoles() {
    await this.setIsAppAdminUser();
    this.setIsICO();
    this.setIsProfileEditor();
    this.setIsARManager();
    this.setIsTenantUser();
    this.setIsCFMO();
    this.setIsAC();
    this.setIsAR();
    this.setIsFinanceManager();
    this.setIsOUPD();
    this.setIsVendorUserOnAnyProject();
    this.setIsEngineerUserOnAnyProject();
    this.setCanImpersonateTenantUser();
    this.setIsStaffUserOnAnyModule();
    this.setIsCreedEditor();
    this.setIsBuildingsDepartmentsSuitesEditor();
    this.setIsExtSecurity();
    this.setIsWorkspaceManager();
    this.setIsWorkspaceManagerOnAnyModule();
    this.setIsDirectoryManager();
    this.setIsDataAnalyst();
    this.setIsAccountCoordinatorOnAnyModule();
    await this.setCeoUser();
  }

  // reset all the properties again
  private resetAuthRoles(): void {
    this.setAuthRoles();
  }

  // checks if the current user is an app admin and set the value
  private async setIsAppAdminUser() {
    try {
      this.isAppAdminUser =
        intersection(this.getLoggedInUser().scopes.app[this.CURRENT_APP], this.appAdminRoles).length > 0;
    } catch {
      this.isAppAdminUser = false;
    }
  }

  private async setCeoUser() {
    const authToken = this.getAuthToken();
    try {
      if (!this.ceo && authToken) {
        const allCEOs = await this.userService.getUsersByRole(ApplicationRole.CEO, 1).toPromise();
        if (allCEOs?.length) {
          this.ceo = allCEOs[0];
        }
      }
    } catch {
      this.ceo = null;
    }
  }

  public isModuleProjectManager(workspace_id: number) {
    try {
      return (
        intersection(this.getLoggedInUser().scopes.module[workspace_id], this.moduleProjectManager).length > 0 ||
        this.isAppAdminUser
      );
    } catch {
      return false;
    }
  }

  public isWorkspaceTopicEditor(workspace_id: number) {
    try {
      return intersection(this.getLoggedInUser().scopes.module[workspace_id], this.workspaceTopicEditor).length > 0;
    } catch {
      return false;
    }
  }

  /**
   * @param workspaceId Workspace ID to be checked. This will check against moduleService.workspace_id if left null.
   */
  public isUserWorkspaceAdmin(workspaceId?: number) {
    try {
      return (
        intersection(
          this.getLoggedInUser().scopes.module[workspaceId || this.moduleService?.workspace_id],
          this.moduleAdminRoles
        ).length > 0 ||
        this.isCFMO ||
        this.isACUser ||
        this.isAppAdminUser ||
        this.ceo?.id === this.getLoggedInUser().id
      );
    } catch {
      return false;
    }
  }

  // Checks to see if a Director of Facility Solutions is needed for the given module
  // The title has changed from Director of Facility Solutions to Chief Technology Officer wherever it appears in the ui
  // But the backend conditions will continue to reference DFS due to it being integrated into the app.
  public needsDFSManager(workspaceId?: Workspace): boolean {
    return !!this.moduleService.dfsWorkspaces?.find((wo) => wo.id === workspaceId);
  }

  // Checks to see if the review is an open type according to the TaskAccessoryType
  public isOpenReview(taskAccessoryType: TaskAccessoryType): boolean {
    return this.openReviewTypes.includes(taskAccessoryType);
  }

  // Checks to see if the passed in review type can be edited using convert-task-dialog
  public reviewIsEditable(taskAccessoryType: TaskAccessoryType): boolean {
    return !taskAccessoryType || this.editableReviewTypes.includes(taskAccessoryType);
  }

  // Checks to see if the passed in review type can be edited using convert-task-dialog
  public reviewHasDigitalSignatures(taskAccessoryType: TaskAccessoryType): boolean {
    return this.signatureReviewTypes.includes(taskAccessoryType);
  }

  //
  public reviewedByVendor(taskAccessoryType: TaskAccessoryType): boolean {
    return this.vendorReviewTypes.includes(taskAccessoryType);
  }

  /**
   * @param workspaceId Workspace ID to be checked. This will check against moduleService.workspace_id if left null.
   */
  public isUserWorkspaceStaff(workspaceId?: number) {
    let userStaffRoles =
      this.getLoggedInUser()?.scopes?.module?.[workspaceId || this.moduleService?.workspace_id] || [];
    const userDispatchRoles = this.getLoggedInUser()?.scopes?.module?.[Workspace.Dispatch];

    if (workspaceId !== Workspace.Dispatch && userDispatchRoles?.length) {
      userStaffRoles = uniq([...userStaffRoles, ...userDispatchRoles]);
    }

    try {
      return (
        intersection(userStaffRoles, this.staffRoles).length > 0 ||
        this.isUserWorkspaceAdmin(workspaceId || this.moduleService?.workspace_id) ||
        this.isICO
      );
    } catch {
      return false;
    }
  }

  // checks if the current user is a tenant and set the value
  private setIsTenantUser(): void {
    try {
      const currentUser = this.getLoggedInUser();
      this.isTenantUser =
        intersection(this.getUserRoles(), this.tenantRoles).length > 0 ||
        (currentUser && currentUser.user_type_id === 2);
    } catch {
      this.isTenantUser = false;
    }
  }

  private setIsCFMO(): void {
    try {
      this.isCFMOUser =
        intersection(this.getUserRoles(), [ApplicationRole.ChiefFacilitiesManagementOfficer]).length > 0;
    } catch {
      this.isCFMOUser = false;
    }
  }

  private setIsAC(): void {
    try {
      this.isACUser = intersection(this.getUserRoles(), [ApplicationRole.AccountCoordinator]).length > 0;
    } catch {
      this.isACUser = false;
    }
  }

  private setIsAR(): void {
    try {
      this.isARUser = intersection(this.getUserRoles(), [ApplicationRole.AuthorizedRequester]).length > 0;
    } catch {
      this.isARUser = false;
    }
  }

  private setIsFinanceManager(): void {
    try {
      this.isFinanceManagerUser = !!intersection(this.getUserRoles(), [ApplicationRole.FinanceManager]).length;
    } catch {
      this.isFinanceManagerUser = false;
    }
  }

  private setIsDataAnalyst(): void {
    try {
      this.isDataAnalystUser = !!intersection(this.getUserRoles(), [ApplicationRole.DataAnalyst]).length;
    } catch {
      this.isDataAnalystUser = false;
    }
  }

  private setIsOUPD(): void {
    try {
      this.isOUPDUser = intersection(this.getUserRoles(), [ApplicationRole.OUPDUser]).length > 0;
    } catch {
      this.isOUPDUser = false;
    }
  }

  private setIsICO(): void {
    try {
      this.isICOUser = intersection(this.getUserRoles(), [ApplicationRole.InventoryControlOfficer]).length > 0;
    } catch {
      this.isICOUser = false;
    }
  }

  private setIsProfileEditor(): void {
    try {
      this.isProfileEditorUser = intersection(this.getUserRoles(), [ApplicationRole.ProfileEditor]).length > 0;
    } catch {
      this.isProfileEditorUser = false;
    }
  }

  private setIsARManager(): void {
    try {
      this.isARManagerUser = intersection(this.getUserRoles(), [ApplicationRole.ARManager]).length > 0;
    } catch {
      this.isARManagerUser = false;
    }
  }

  private setIsWorkspaceManager(): void {
    try {
      this.isWorkspaceManagerUser = intersection(this.getUserRoles(), [ApplicationRole.WorkspaceManager]).length > 0;
    } catch {
      this.isWorkspaceManagerUser = false;
    }
  }

  private setIsWorkspaceManagerOnAnyModule(): void {
    try {
      const module: Record<number, ApplicationRole[]> = this.getLoggedInUser()?.scopes?.module;

      this.isWorkspaceManagerUserOnAnyModule = Object.values(module).some((roleIds: number[]) =>
        roleIds.includes(ApplicationRole.WorkspaceManager)
      );
    } catch (_) {
      this.isWorkspaceManagerUserOnAnyModule = false;
    }
  }

  private setIsAccountCoordinatorOnAnyModule(): void {
    try {
      const module: Record<number, ApplicationRole[]> = this.getLoggedInUser()?.scopes?.module;

      this.isACOnAnyModule = Object.values(module).some((roleIds: number[]) =>
        roleIds.includes(ApplicationRole.AccountCoordinator)
      );
    } catch (_) {
      this.isACOnAnyModule = false;
    }
  }

  // checks if the current user is a staff and set the value
  public setIsStaffUserOnAnyModule(): void {
    try {
      const currentUser = this.getLoggedInUser();
      this.isStaffUserOnAnyModule =
        intersection(this.getUserRoles(), this.staffRoles).length > 0 ||
        (currentUser && currentUser.user_type_id === 1);
    } catch {
      this.isStaffUserOnAnyModule = false;
    }
  }

  public setIsCreedEditor() {
    try {
      const currentUser = this.getLoggedInUser();
      this.isCreedEditor = intersection(this.getUserRoles(), [ApplicationRole.CreedEditor]).length > 0;
    } catch {
      this.isCreedEditor = false;
    }
  }

  public setIsExtSecurity() {
    try {
      this.isExtSecurityUser = intersection(this.getUserRoles(), [ApplicationRole.ExtSecurity]).length > 0;
    } catch {
      this.isExtSecurityUser = false;
    }
  }

  public setIsBuildingsDepartmentsSuitesEditor() {
    try {
      this.isBuildingsDepartmentsSuitesUser =
        intersection(this.getUserRoles(), [ApplicationRole.BuildingsDepartmentsSuitesManager]).length > 0;
    } catch {
      this.isBuildingsDepartmentsSuitesUser = false;
    }
  }

  // checks if the current user is a vendor (excluding engineers) and set the value
  private setIsVendorUserOnAnyProject(): void {
    try {
      const currentUser = this.getLoggedInUser();
      this.isVendorUserOnAnyProject =
        intersection(this.getUserRoles(), this.vendorRoles).length > 0 ||
        (currentUser && currentUser.user_type_id === UserType.Vendor);
    } catch {
      this.isVendorUserOnAnyProject = false;
    }
  }

  // checks if the current user is a vendor
  private setIsEngineerUserOnAnyProject(): void {
    try {
      this.isEngineerUserOnAnyProject = intersection(this.getUserRoles(), this.engineerVendorRoles).length > 0;
    } catch {
      this.isEngineerUserOnAnyProject = false;
    }
  }

  // checks if the current user can impersonate a tenant when approving pebs/etc and set the value
  private setCanImpersonateTenantUser(): void {
    try {
      this.canImpersonateTenantUser = intersection(this.getUserRoles(), this.impersonateTenantRoles).length > 0;
    } catch {
      this.canImpersonateTenantUser = false;
    }
  }

  public setIsDirectoryManager() {
    try {
      this.isDirectoryManagerUser = intersection(this.getUserRoles(), [ApplicationRole.DirectoryManager]).length > 0;
    } catch {
      this.isDirectoryManagerUser = false;
    }
  }

  get isAppAdmin(): boolean {
    return this.isAppAdminUser;
  }

  get isStaffOnAnyModule(): boolean {
    return this.isStaffUserOnAnyModule;
  }

  get isTenant(): boolean {
    return this.isTenantUser;
  }

  get isCFMO(): boolean {
    return this.isCFMOUser;
  }

  get isAC(): boolean {
    return this.isACUser;
  }

  get isAR(): boolean {
    return this.isARUser;
  }

  get isFinanceManager(): boolean {
    return this.isFinanceManagerUser;
  }

  get isDataAnalyst(): boolean {
    return this.isDataAnalystUser;
  }

  get isOUPD(): boolean {
    return this.isOUPDUser;
  }

  get isICO(): boolean {
    return this.isICOUser;
  }

  get isProfileEditor(): boolean {
    return this.isProfileEditorUser;
  }

  get isARManager(): boolean {
    return this.isARManagerUser;
  }

  get isVendorOnAnyProject(): boolean {
    return this.isVendorUserOnAnyProject;
  }

  get isEngineerOnAnyProject(): boolean {
    return this.isEngineerUserOnAnyProject;
  }

  get canImpersonateTenant(): boolean {
    return this.canImpersonateTenantUser;
  }

  get isLoggedIn(): boolean {
    return this.isUserLoggedIn;
  }

  set isLoggedIn(value: boolean) {
    this.isUserLoggedIn = value;
  }

  get isCreedUser(): boolean {
    return this.isCreedEditor || this.isAppAdmin;
  }

  get isExtSecurity(): boolean {
    return this.isExtSecurityUser;
  }

  setUserIsLoggedIn() {
    this.isLoggedIn = !!this.getAuthToken();
  }

  get isBuildingsDepartmentsSuitesManager(): boolean {
    return this.isBuildingsDepartmentsSuitesUser || this.isAppAdmin;
  }

  get isWorkspaceManager(): boolean {
    return this.isWorkspaceManagerUser;
  }

  get isWorkspaceManagerOfAnyModule(): boolean {
    return this.isWorkspaceManagerUserOnAnyModule;
  }

  public get canInviteUser(): boolean {
    return this.isStaffOnAnyModule;
  }

  get isDirectoryManager(): boolean {
    return this.isDirectoryManagerUser;
  }

  login(
    credentials: { email: string; password: string },
    getRefreshToken?: boolean,
    goToDashboard: boolean = true
  ): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Basic ${btoa(`${credentials.email}:${credentials.password}`)}`,
      }),
    };
    return this.http.post(`${this.loginUrl}?include_refresh=${getRefreshToken}`, null, httpOptions).pipe(
      tap((result: ServiceResponse) => {
        const tokens: Auth = result.data;
        this.storeTokens(tokens);
        this.socketService.getSocket();
        if (goToDashboard) {
          const url = this.route.snapshot.queryParams.returnUrl || '/';
          this.router.navigateByUrl(url.toLowerCase().includes('/login') ? '/' : url);
        }
      }),
      catchError((e) => {
        if (e.status !== 401) {
          return this.handleErrorService.handleError(e);
        } else {
          return throwError(e);
        }
      }),
      finalize(() => {
        this.setUserIsLoggedIn();
      })
    );
  }

  public impersonateUser(userId) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus(`Beginning Impersonation`);
    return this.http.get(`${this.impersonateUrl}/${userId}`).pipe(
      tap((result: ServiceResponse) => {
        const tokens = result.data;
        this.storeTokens(tokens);
        this.isImpersonating = true;
        window.location.reload();
      }),
      catchError((e) => {
        if (e.status !== 401) {
          return this.handleErrorService.handleError(e);
        } else {
          return throwError(e);
        }
      })
    );
  }

  public endImpersonation() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus(`Ending impersonation`);
    localStorage.removeItem('impersonation_token');
    this.isImpersonating = false;
    window.location.reload();
  }

  private storeTokens(tokens) {
    if (tokens.auth_token) {
      localStorage.setItem('auth_token', tokens.auth_token);
    }
    if (tokens.refresh_token) {
      localStorage.setItem('refresh_token', tokens.refresh_token);
    }
    if (tokens.impersonation_token) {
      localStorage.setItem('impersonation_token', tokens.impersonation_token);
    }
    this.getLoggedInUser(true);
    this.setAuthRoles();
  }

  logout() {
    return this.http.post(`${this.logoutUrl}`, null).pipe(
      map(() => {
        this.removeTokens();
        this.resetAuthRoles(); // remove the roles
        this.currentUser = null;
        this.moduleService.clearWorkspace();
      }),
      catchError((e) => this.handleErrorService.handleError(e)),
      finalize(() => {
        this.setUserIsLoggedIn();
        this.router.navigateByUrl('/login', /* Removed unsupported properties by Angular migration: queryParams. */ {});
      })
    );
  }

  refreshTokens() {
    return this.http.post(`${this.refreshUrl}`, { refresh_token: this.getRefreshToken() }).pipe(
      map((result: ServiceResponse) => {
        const tokens: Auth = result.data;
        this.storeTokens(tokens);
        return tokens;
      })
    );
  }

  removeTokens(): void {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('impersonation_token');
    this.isLoggedIn = false;
  }

  isAuthTokenValid(authToken) {
    const claims = JSON.parse(
      atob(authToken.substr(authToken.indexOf('.') + 1, authToken.lastIndexOf('.') - authToken.indexOf('.') - 1))
    );
    const exp = claims ? claims.exp * 1000 : null;
    const isValid = exp > new Date().valueOf();
    return isValid;
  }

  // Turned into a getter
  // isLoggedIn() {
  //   return !!this.getAuthToken();
  // }

  getAuthToken() {
    const impersonationToken = localStorage.getItem('impersonation_token');
    if (impersonationToken) {
      this.isImpersonating = true;
      return impersonationToken;
    } else {
      return localStorage.getItem('auth_token');
    }
  }

  getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }

  getAccess(fields: string[], apiFilters?: APIFilter[], limit?: number): Observable<Access[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.accessUrl}?fields=${fields.join(',')}&limit=${limit?.toString() || ''}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const access: Access[] = result.data.access;
          return access;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getCompanyAccess(fields: string[], apiFilters?: APIFilter[], limit?: number): Observable<Access[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.companyAccessUrl}?fields=${fields.join(',')}&limit=${limit?.toString() || ''}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const access: Access[] = result.data.access_companies;
          return access;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  async getProjectScopes() {
    const user = this.getLoggedInUser();
    if (!user) {
      return;
    }
    const userAccessFilter: APIFilter[] = [
      { type: 'field', field: 'user_id', value: user.id },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'resource_type_id', value: ResourceType.Project },
    ];
    const companyAccessFilter: APIFilter[] = [
      { type: 'field', field: 'user_id', value: user.id },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'resource_type_id', value: ResourceType.Project },
    ];
    const userAccessPromise = this.getAccess(['role_id', 'resource_id'], userAccessFilter).toPromise();
    const companyAccessPromise = this.getCompanyAccess(['role_id', 'resource_id'], companyAccessFilter).toPromise();
    let access = {};
    await Promise.all([userAccessPromise, companyAccessPromise]).then((res) => {
      res.forEach((data) => {
        data.forEach((a) => {
          if (access[a.resource_id]) {
            access[a.resource_id].push(a.role_id);
          } else {
            access[a.resource_id] = [a.role_id];
          }
        });
      });
      user.scopes.project = access;
      this.setAuthRoles();
    });
  }

  grantAccess(accessToAdd: Access) {
    return this.http.post(this.accessUrl, accessToAdd).pipe(
      map((result: ServiceResponse) => {
        const createdAccess: Access = result.data.access;
        return createdAccess;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  revokeAccess(accessId: number) {
    return this.http.delete(`${this.accessUrl}/${accessId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // grabs all roles the user has, regardless of project (however, still app/module restricted)
  private getUserRoles(): number[] {
    const roles = [];
    const userRoles = this.getLoggedInUser().scopes;
    if (userRoles.app) {
      userRoles.app[this.CURRENT_APP].forEach((role) => {
        if (!roles.includes(role)) {
          roles.push(role);
        }
      });
    }
    if (userRoles?.module && userRoles?.module[this.moduleService?.workspace_id]) {
      userRoles.module[this.moduleService.workspace_id].forEach((role) => {
        if (!roles.includes(role)) {
          roles.push(role);
        }
      });
    }
    if (userRoles.project) {
      for (const projectId of Object.keys(userRoles.project)) {
        userRoles.project[projectId].forEach((role) => {
          if (!roles.includes(role)) {
            roles.push(role);
          }
        });
      }
    }
    return roles;
  }

  // checks if the current user is an admin on the current project or module
  // TODO define admin roles better, currently only 1, 10, 11 are admin roles
  public isProjectAdmin(projectId: number, workspaceId?: number): boolean {
    try {
      return (
        intersection(this.getLoggedInUser().scopes.project?.[projectId], this.projectAdminRoles).length > 0 ||
        this.isUserWorkspaceAdmin(workspaceId) ||
        this.isAppAdminUser
      );
    } catch {
      return false;
    }
  }

  // checks if the current user is an architect on the current project
  public isProjectArchitect(projectId: number): boolean {
    try {
      return (
        intersection(this.getLoggedInUser().scopes.project[projectId], [ApplicationRole.ProjectArchitect]).length > 0
      );
    } catch {
      return false;
    }
  }

  // checks if the current user is an architect on the current project
  public isProjectEngineer(projectId: number): boolean {
    try {
      return (
        intersection(this.getLoggedInUser().scopes.project[projectId], [ApplicationRole.ProjectEngineer]).length > 0
      );
    } catch {
      return false;
    }
  }

  // checks if the current user is an architect on the current project
  public isProjectVendor(projectId: number): boolean {
    try {
      return intersection(this.getLoggedInUser().scopes.project[projectId], [ApplicationRole.ProjectVendor]).length > 0;
    } catch {
      return false;
    }
  }

  public rolesAdminLevels(project) {
    return [
      project?.project_manager_id,
      project?.architect_id,
      project?.account_coordinator_id,
      project?.dfs_id,
      project?.cfmo_id,
    ];
  }

  public noneProjectAdminLevels(custodyChain: ArfCustodyChain) {
    return [custodyChain?.ac_id, custodyChain?.cto_id, custodyChain?.cfmo_id];
  }

  // checks if the current user is of type pm/wm/app admin, or the original creator of the file
  public allowedToDeleteFiles(projectId: number, createdById: number, workspaceId?: number): boolean {
    return this.getLoggedInUser().id === createdById || this.isProjectAdmin(projectId, workspaceId);
  }

  // checks if the current user is of type pm/wm/app admin
  public allowedToDeleteTasks(projectId: number, createdById: number, workspaceId?: number): boolean {
    return (
      this.getLoggedInUser().id === createdById ||
      this.isProjectAdmin(projectId, workspaceId) ||
      this.isUserWorkspaceStaff(workspaceId)
    );
  }

  // checks if the current user has the passed role
  // TODO check if roles of alternate resource type can appear in lower tier resource types
  // TODO if so, then we need to split this into a check only for the given resource type
  public currentUserIsOfAppRole(role_id: ApplicationRole): boolean {
    const scopes = this.getLoggedInUser()?.scopes;

    if (scopes?.app && scopes.app[this.CURRENT_APP] && scopes.app[this.CURRENT_APP].includes(+role_id)) {
      return true;
    }
    return false;
  }

  public currentUserIsOfWorkspaceRole(role_id: ApplicationRole, workspace_id: number): boolean {
    const scopes = this.getLoggedInUser()?.scopes;

    if (scopes?.module && scopes.module[workspace_id] && scopes.module[workspace_id].includes(+role_id)) {
      return true;
    }
    return false;
  }

  public currentUserIsOfProjectRole(role_id: ApplicationRole, project_id: number): boolean {
    const scopes = this.getLoggedInUser()?.scopes;

    if (scopes?.project && scopes.project[project_id] && scopes.project[project_id].includes(+role_id)) {
      return true;
    }
    return false;
  }

  public getLoggedInUser(updateUser = false) {
    if (this.currentUser && !updateUser) {
      return this.currentUser;
    }

    const authToken = this.getAuthToken();
    if (authToken) {
      const claims = JSON.parse(
        atob(authToken.substr(authToken.indexOf('.') + 1, authToken.lastIndexOf('.') - authToken.indexOf('.') - 1))
      );

      if (claims) {
        const user: User = {
          id: claims.id,
          email: claims.email,
          first_name: claims.first_name,
          last_name: claims.last_name,
          department_id: claims.department_id,
          company_id: claims.company_id,
          scopes: claims.scopes,
          user_type_id: claims.user_type_id,
          default_module_id: claims.default_module_id,
        };

        this.currentUser = user;
        return user;
      }
    }

    return null;
  }

  public getPMProjectIds() {
    const ids = [];
    if (this.getLoggedInUser().scopes && this.getLoggedInUser().scopes.project) {
      Object.getOwnPropertyNames(this.getLoggedInUser().scopes.project).forEach((key) => {
        if (this.getLoggedInUser().scopes.project[key].indexOf(ApplicationRole.ProjectManager) !== -1) {
          ids.push(+key);
        }
      });
    }
    return ids;
  }

  public troubleshootLogin(email: string): Observable<User> {
    return this.http.get(`${this.troubleshootUrl}/${email}`).pipe(
      map((result: ServiceResponse) => {
        return result.data;
      })
    );
  }

  public isCompanyEditor(company_id) {
    const company_ids = this.getLoggedInUser().scopes.company;
    if (!company_id) {
      return false;
    }
    return company_ids?.[company_id]?.indexOf(ApplicationRole.CompanyEditor) >= 0;
  }
}
